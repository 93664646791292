<template>
  <div class="app-container w-full">
    <div class="h-full block">
      <div class="h-full">
        <div
          class="loading-container absolute inset-0 z-10 w-full h-full bg-white flex justify-center items-center"
          :class="{ active: isLoading }"
        >
          <Loading />
        </div>
        <router-view></router-view>

        <EditProfile
          v-if="editProfile"
          @cancel="$store.dispatch('profile/toggleEditProfile')"
        />
        <NotificationsManager />
      </div>
    </div>
  </div>
</template>

<script>
import EditProfile from "@/components/EditProfile"
import NotificationsManager from "@/components/NotificationsManager"
import Loading from "@/components/Loading"

export default {
  name: "App",
  components: {
    EditProfile,
    NotificationsManager,
    Loading,
  },
  data() {
    return {}
  },
  watch: {
    isLoggedIn: {
      handler() {
        if (!this.isLoggedIn) {
          this.$router.push("/login")
        } else {
          this.fetchPermissions()
        }
      },
    },
  },
  mounted() {
    this.fetchPermissions()
  },
  computed: {
    isLoading() {
      return this.$store.state.global.loading
    },
    editProfile() {
      return this.$store.state.profile.editProfile
    },
    config() {
      return this.$store.state.global.appConfig || {}
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"]
    },
  },
  methods: {
    fetchPermissions() {
      this.$store.dispatch("auth/fetchPermissions")
    },
  },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  @apply h-full;
}

body {
  @apply absolute inset-0 overflow-auto;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: rgba(0, 0, 0, 0.9);

  touch-action: manipulation;

  &.lock {
    @apply overflow-hidden;
    touch-action: none;
    -ms-touch-action: none;
  }

  overscroll-behavior: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --color: 150, 55%;
  --l: 34%;

  --color-light: 150, 55%;
  --l-light: 86%;

  --primary-color: hsl(var(--color), var(--l));
  --primary-light-color: hsl(var(--color-light), var(--l-light));
  --primary-danger-color: rgba(185, 28, 28, 1);

  --safe-area-inset-bottom: 0px;
  --safe-area-inset-top: 0px;
  --safe-area-inset-left: 0px;
  --safe-area-inset-right: 0px;

  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);

  --navbar-height: 72px;
}

.app-container {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  user-select: none;
  background-color: transparent;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  // height: calc(100% + constant(safe-area-inset-top));
  // height: calc(100% + env(safe-area-inset-top));
}

.loading-container {
  @apply opacity-0 pointer-events-none transition-opacity;
}

.loading-container.active {
  @apply opacity-100 pointer-events-auto;
  padding-top: calc(
    env(safe-area-inset-top) + calc(20px + var(--navbar-height))
  );
}

@supports (-webkit-touch-callout: none) {
  input[type="datetime-local"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 3.2em;
  }

  input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 3.2em;
  }
}

img.app-logo {
  clip-path: inset(0px round 14.6667px);
}

.navbar-container-items {
  @apply fixed top-0 right-0 z-50;

  padding-left: calc(1.25rem + var(--safe-area-inset-left));
  padding-right: calc(1.25rem + var(--safe-area-inset-right));
  padding-top: var(--safe-area-inset-top);
  height: calc(var(--safe-area-inset-top) + var(--navbar-height));
  width: 50%;
}
</style>
