import Vue from "vue"
import App from "./App.vue"
import Modal from "./components/Modal.vue"
import "./assets/index.css"
import VuePageStack from "vue-page-stack"
import VueFormulate from "@braid/vue-formulate"
import { pt } from "@braid/vue-formulate-i18n"
import Radio from "@/components/inputs/Radio"
import File from "@/components/inputs/File"
import VueFormulateDatepicker from "@/components/inputs/VueFormulateDatepicker"
import VueFormulateResizableTextarea from "@/components/inputs/VueFormulateResizableTextarea"
import VueI18n from "vue-i18n"
import DateLocal from "@/utils/date-local"
import CurrentUser from "@/utils/current-user"
import noAvatarDIsplay from "@/utils/no-avatar-display"
import nameOrEmailDisplay from "@/utils/name-or-email-display"
import cartItemsDisplay from "@/utils/cart-items-display"
import userFirstName from "@/utils/user-first-name"
import VueLazyload from "vue-lazyload"
import Cleave from "cleave.js"
import pick from "object.pick"
import GAuth from "vue-google-oauth2"
import VueHorizontal from "vue-horizontal"
import VueScreen from "vue-screen"
import { App as CapacitorApp } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"
import { PushNotifications } from "@capacitor/push-notifications"
import { Device } from "@capacitor/device"
import PushNotificationsHandler from "@/utils/push-notifications-handler"
import Bugsnag from "@bugsnag/js"
import { Example } from "plist-reader"
import axios from "@/api/base/axios"
import AppUpdater from "@/utils/app-updater"
import Storage from "@/utils/storage"
import AppConfigHandler from "@/utils/AppConfigHandler"
import TimeAgo from "javascript-time-ago"
import timeAgoPt from "javascript-time-ago/locale/pt.json"
import { Badge } from "@capawesome/capacitor-badge"
import priceFormatter from "./utils/priceFormatter"

TimeAgo.addDefaultLocale(timeAgoPt)
Vue.use(VueScreen)
Vue.use(VueHorizontal)
Vue.use(VueLazyload, {
  observer: true,
})
Vue.use(VueFormulate, {
  validationNameStrategy: (vm) => {
    if (vm.$attrs.localeName) {
      return vm.$attrs.localeName
    }
    return vm.context.label || vm.context.name
  },
  plugins: [pt],
  locale: "pt",
  useInputDecorators: false,
  classes: {
    outer: "mb-5",
    input: (context, classes) => {
      let normalClasses = undefined

      if (context.type == "checkbox") {
        normalClasses = ""
      } else {
        normalClasses =
          "h-11 border p-3 text-justify rounded-lg shadow-sm border-gray-200 outline-none focus:border-primary w-full"
      }

      const errorClasses = "border-red-700"
      const result = classes.concat([normalClasses])

      if (context.hasErrors) {
        result.push(errorClasses)
      }

      return result
    },
    wrapper: (context, classes) => {
      if (context.type == "checkbox") {
        return classes.concat(["flex w-full space-x-1"])
      }
    },
    label: "text-gray-800 block mb-2",
    placeholder: "text-xs mb-1 text-gray-600",
    help: "mt-1 text-xs text-gray-600",
    error: "text-red-700 text-xs my-1",
  },
  library: {
    radio: {
      classification: "text",
      component: "Radio",
      slotProps: {
        component: ["options"],
      },
    },
    file: {
      classification: "file",
      component: "File",
      slotProps: {
        component: ["renderOnlyIcon"],
      },
    },
    datepicker: {
      classification: "text",
      component: "VueFormulateDatepicker",
    },
    resizableTextarea: {
      classification: "text",
      component: "VueFormulateResizableTextarea",
    },
  },
})

Vue.directive("cleave", {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
    const event = new Event("input", { bubbles: true })
    setTimeout(function () {
      el.value = el.cleave.properties.result
      el.dispatchEvent(event)
    }, 100)
  },
})

Vue.mixin(DateLocal)
Vue.mixin(CurrentUser)
Vue.mixin(noAvatarDIsplay)
Vue.mixin(nameOrEmailDisplay)
Vue.mixin(cartItemsDisplay)
Vue.mixin(userFirstName)
Vue.mixin(priceFormatter)
Vue.component("Radio", Radio)
Vue.component("Modal", Modal)
Vue.component("File", File)
Vue.component("VueFormulateDatepicker", VueFormulateDatepicker)
Vue.component("VueFormulateResizableTextarea", VueFormulateResizableTextarea)
Vue.config.productionTip = false
Vue.prototype.$pick = pick

let isNativePlatform = false
let instance = null

try {
  isNativePlatform = Capacitor.isNativePlatform()
} catch {
  isNativePlatform = false
}

if (!isNativePlatform) {
  Vue.use(GAuth, {
    clientId:
      "634697566924-kp07jmuv8etuspfoosdoepmbpuku9l7h.apps.googleusercontent.com",
    scope: "profile email",
    prompt: "select_account",
  })
} else {
  CapacitorApp.removeAllListeners()
  Bugsnag.start({ apiKey: "2caf37122a2b4f753c86e9c07225fb36" })
}

const setPushNotifications = async (store) => {
  if (isNativePlatform) {
    PushNotifications.addListener("registration", (token) => {
      instance.$store.dispatch("global/setPushToken", token.value)
    })

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async ({ notification }) => {
        if (notification.data?.type == "dosage_create") {
          store.dispatch("notifications/addModalNotification", {
            ...notification.data,
            show: true,
          })
        } else {
          Vue.prototype.$notificationHandler.fetch(
            notification.data?._uuid,
            false,
            true
          )
        }
        PushNotifications.removeAllDeliveredNotifications()
      }
    )

    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification) => {
        let show = false
        // tell android to show banner in app
        if (Capacitor.getPlatform() === "android") {
          show = true
        }
        Vue.prototype.$notificationHandler.fetch(notification.data?._uuid, show) // do not navigate
        PushNotifications.removeAllDeliveredNotifications()
      }
    )

    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive === "granted") {
      PushNotifications.register()
    }

    CapacitorApp.addListener("appStateChange", async ({ isActive }) => {
      let permStatus = await PushNotifications.checkPermissions()

      if (isActive && permStatus.receive === "granted") {
        PushNotifications.removeAllDeliveredNotifications()
      }
    })
  }
}

const deepMerge = (target, source) => {
  const isObject = (obj) => obj && typeof obj === "object"

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue)
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMerge(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })

  return target
}

const loadLocaleMessages = (customLocale) => {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )

  let messages = {}

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  messages = deepMerge(messages, customLocale)

  return messages
}

const initApp = async () => {
  let api_url = null
  let store_domain = null
  let store_subdomain = null

  if (isNativePlatform) {
    const promise1 = Example.echo({ plist: "Info", key: "API_URL" })
    const promise2 = Example.echo({ plist: "Info", key: "STORE_DOMAIN" })
    const promise3 = Example.echo({ plist: "Info", key: "STORE_SUBDOMAIN" })

    const result = await Promise.all([promise1, promise2, promise3]).then(
      (values) => {
        return {
          api_url: values[0].value,
          store_domain: values[1].value,
          store_subdomain: values[2].value,
        }
      }
    )

    api_url = result.api_url
    store_domain = result.store_domain
    store_subdomain = result.store_subdomain
  } else {
    api_url = process.env.VUE_APP_API_URL || "http://localhost:3000/api/v1"
    store_domain = process.env.VUE_APP_STORE_DOMAIN
    store_subdomain = process.env.VUE_APP_STORE_SUBDOMAIN
  }

  const headers = {
    "Content-Type": "application/json",
  }

  if (store_domain) {
    headers["X-Store-Domain"] = store_domain
  }

  if (store_subdomain) {
    headers["X-Store-Subdomain"] = store_subdomain
  }

  axios.defaults.baseURL = api_url

  Object.entries(headers).forEach((entry) => {
    axios.defaults.headers.common[entry[0]] = entry[1]
  })

  const { default: router } = await import("./router")
  const { default: store } = await import("./store")

  // load stored defaults
  const userData = JSON.parse(await Storage.get("user")) || {}
  const token = await Storage.get("token")

  // trigger store user/token data from storage
  store.dispatch("auth/init", { user: userData, token })
  // init app config

  const appConfigHandler = new AppConfigHandler(store)
  Vue.prototype.$appConfigHandler = appConfigHandler
  await appConfigHandler.init()

  Vue.use(VuePageStack, { router })
  Vue.use(VueI18n)

  // load stored locale
  const storedLocale = await Storage.get("lang")
  const fallbackLocale = "pt"

  const i18n = new VueI18n({
    locale: storedLocale || fallbackLocale,
    fallbackLocale: fallbackLocale,
    messages: loadLocaleMessages(
      appConfigHandler.currentConfig.custom_locale || {}
    ),
  })

  appConfigHandler.setI18n(i18n)

  instance = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")

  try {
    await AppUpdater.start()
  } catch (e) {
    console.log("error app udate service", e)
  }

  store.dispatch("global/setLoading", false)

  Vue.prototype.$notificationHandler = new PushNotificationsHandler(
    instance.$router,
    instance.$store
  )

  Device.getInfo().then(async (info) => {
    if (!info.isVirtual) {
      setPushNotifications(store)

      CapacitorApp.addListener("appStateChange", ({ isActive }) => {
        if (isActive) {
          try {
            Badge.clear()
            AppUpdater.start()
          } catch (e) {
            console.log("error app udate service", e)
          }
        }
      })
    }
  })
}

initApp()
